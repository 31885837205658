<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <!-- 안전보건정보 목록 -->
    <c-table
      ref="table"
      title="LBL0000950"
      tableId="safeHealSurvey01"
      :pagePerRow="grid.pagePerRow"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 안전보건정보 조사표 -->
          <c-btn label="LBL0000951" icon="assignment" @btnClicked="printChecklist" />
          <!-- 공정흐름도 -->
          <!-- <c-btn label="LBL0000953" icon="account_tree" @btnClicked="printProcessFlowChart" /> -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'sopNames' && props.row.sopNames">
          <q-chip
            v-for="(item, index) in chipDatas(props.row['sopNames'])"
            outline square
            :key="index"
            color="light-green"
            text-color="white"
            style="margin-bottom:4px !important;">
            {{item}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safe-heal-survey',
  data() {
    return {
      searchParam: {
        plantCd: null,
      },
      grid: {
        pagePerRow: {
          pageNumber: 100,
        },
        merge: [
          { index: 0, colName: 'upProcessCd' },
          { index: 1, colName: 'processCd' },
          { index: 2, colName: 'equipmentCd' },
          { index: 3, colName: 'equipmentCd' },
          { index: 4, colName: 'materialCd' },
          { index: 6, colName: 'materialCd' },
          { index: 7, colName: 'materialCd' },
          { index: 8, colName: 'processCd' },
          { index: 9, colName: 'processCd' },
        ],
        columns: [
          {
            name: 'kras',
            field: 'kras',
            // 공정 평가 대상
            label: 'LBL0000952',
            align: 'center',
            child: [
              {
                name: 'upProcessName',
                field: 'upProcessName',
                label: 'LBLPROCESS',
                align: 'center',
                style: 'width:120px',
                sortable: false,
                type: 'link',
              },
              {
                name: 'processName',
                field: 'processName',
                // 세부공정(작업)
                label: 'LBL0000965',
                align: 'center',
                style: 'width:120px',
                sortable: false,
                type: 'link',
              },
            ]
          },
          {
            name: 'equipment',
            field: 'equipment',
            // 기계기구 및 설비
            label: 'LBL0000964',
            align: 'center',
            child: [
              {
                name: 'equipmentName',
                field: 'equipmentName',
                // 기계기구 및 설비
                label: 'LBL0000964',
                align: 'center',
                style: 'width:200px',
                sortable: false,
                type: 'link',
              },
              {
                name: 'amount',
                field: 'amount',
                label: 'LBLAMOUNT',
                align: 'right',
                type: 'cost',
                style: 'width:70px',
                sortable: false
              },
            ]
          },
          {
            name: 'riskChemprod',
            field: 'riskChemprod',
            // 위험성평가물질
            label: 'LBL0000963',
            align: 'center',
            child: [
              {
                name: 'materialName',
                field: 'materialName',
                // 화학자재
                label: 'LBL0000356',
                align: 'center',
                style: 'width:150px',
                sortable: false,
                type: 'link',
              },
              {
                name: 'chemNmKr',
                field: 'chemNmKr',
                // 화학물질
                label: 'LBL0000962',
                align: 'center',
                style: 'width:200px',
                sortable: false
              },
              {
                name: 'chemDailyVolume',
                field: 'chemDailyVolume',
                // 취급량/일
                label: 'LBL0000961',
                align: 'center',
                style: 'width:100px',
                sortable: false
              },
              {
                name: 'chemDailyHour',
                field: 'chemDailyHour',
                // 취급시간
                label: 'LBL0000960',
                align: 'center',
                style: 'width:100px',
                sortable: false
              },
            ]
          },
          {
            name: 'downPath',
            field: 'downPath',
            // 그 밖의 유해위험정보
            label: 'LBL0000959',
            align: 'center',
            style: 'width:200px',
            type: 'img',
            sortable: false
          },
          {
            name: 'job',
            field: 'job',
            // 작업 평가 대상
            label: 'LBL0000958',
            align: 'center',
            child: [
              {
                name: 'sopNames',
                field: 'sopNames',
                // 작업
                label: 'LBLJOB',
                align: 'left',
                style: 'width:200px',
                sortable: false,
                type: 'custom',
              },
            ]
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      printUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.safeHealSurvey.list.url;
      this.printUrl = selectConfig.ram.safeHealSurvey.print.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.param = this.searchParam;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    chipDatas(val) {
      return this.$_.split(val, '\n');
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      if (!row) return;
      if (col.name === 'upProcessName' || col.name === 'processName') {
        this.popupOptions.target = () => import(`${'@/pages/common/process/processInfoPop.vue'}`);
        this.popupOptions.title = 'LBL0000954'; // 공정 상세
        this.popupOptions.param = {
          processType: col.name === 'upProcessName' ? 'P' : 'C', // P: 상위공정, C: 단위공정
          processCd: col.name === 'upProcessName' ? row.upProcessCd : row.processCd, // 공정코드
        };
        this.popupOptions.width = '60%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      } else if (col.name === 'equipmentName') {
        if (row.equipType === 'equipment') {
          this.popupOptions.title = 'LBL0000955'; // 설비 상세
          this.popupOptions.param = {
            equipmentCd: this.$_.split(row.equipmentCd, '/')[1],
            psmFlag: row ? row.psmFlag : '',
          };
          this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
          this.popupOptions.visible = true;
          this.popupOptions.width = '90%';
          this.popupOptions.closeCallback = this.closePopup;
        } else {
          this.popupOptions.title = "LBL0000956"; // 유해위험기계기구 상세
          this.popupOptions.param = {
            hhmHazardousMachineryId: row.hhmHazardousMachineryId
          };
          this.popupOptions.target = () =>
            import(`${"@/pages/sop/mim/hazardEquipment/hazardEquipmentClassDetail.vue"}`);
          this.popupOptions.visible = true;
          this.popupOptions.width = '90%';
          this.popupOptions.closeCallback = this.closePopup;
        }
      } else if (col.name === 'materialName') {
        this.popupOptions.title = 'LBL0000957'; // 화학자재 상세
        this.popupOptions.param = {
          mdmChemMaterialId: row.mdmChemMaterialId,
        };
        this.popupOptions.target = () => import(`${'@/pages/mdm/mam/chemDetail.vue'}`);
        this.popupOptions.width = '90%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      }
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    printChecklist() {
      let thisVue = this;
      this.$http.url = this.printUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request(
        _result => {
          let fileOrgNm = '안전보건조사표_' + this.$comm.getToday() + '.docx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
    printProcessFlowChart() {

    },
  }
};
</script>
